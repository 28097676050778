.App {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  background: linear-gradient(#212228,#2c2c35);
  /* background: linear-gradient(to right,#ebb302, #fdf100); */
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

/* ----------------------------------------------
 * Generated by Animista on 2019-11-26 16:56:20
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

@keyframes color-change-3x{0%{background:#ee121e}50%{background:#f0334e}100%{background:#b91f05}}


.color-change-3x{animation:color-change-3x 2s ease-out infinite alternate both}